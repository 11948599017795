<template>
  <v-container class="text-center">
    <v-row>
      <v-col class="ml-1">
        <v-switch
          inset
          label="Offline-Funktion"
          :input-value="$store.state.settings.isListView"
          :loading="isSearching"
          :disabled="isDisabled"
          @change="switchView"
        ></v-switch>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :loading="isSearching || restoring || loading"
          :disabled="restoring"
          label="Suche..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-expansion-panels>
        <v-expansion-panel v-for="(data, index) in items" :key="index">
          <v-expansion-panel-header>
            {{ data.label }} - {{ data.objectType }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                {{
                  data.properties?.Stationskennzeichen
                    ? data.properties.Stationskennzeichen + " - "
                    : ""
                }}
                {{
                  data.properties?.kv_kennzeichen
                    ? data.properties.kv_kennzeichen + " - "
                    : ""
                }}
                {{
                  data.properties?.Schlüsselbezeichnung
                    ? data.properties.Schlüsselbezeichnung + " - "
                    : ""
                }}
                {{ data.label }} - {{ data.objectType }}
              </v-card-text>
              <v-card-text v-if="zuwegung(data)">
                Zuwegung: {{ zuwegung(data) }}
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="coordinates(data)" @click="navigateTo(data)">
                  Navigieren
                </v-btn>

                <v-btn
                  v-if="coordinates(data)"
                  @click="toggleToRoutePlanner(data)"
                >
                  <v-icon v-if="existsInRoutePlanner(data)">
                    mdi-map-marker-minus
                  </v-icon>
                  <v-icon v-else> mdi-map-marker-plus </v-icon>
                  Routenplaner
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
      isSearching: false,
      search: "",
      searchIndexedDB: "",
      took: 0,
      expanded: [],
      singleExpand: false,
      headers: [{ text: "Label", value: "label" }],
      onLine: navigator.onLine,
      isDisabled: true,
    };
  },
  computed: {
    ...mapState("poi", ["allItems", "restoring", "loading"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MAPP/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },

    // isOnline() {
    //   return window.navigator.onLine;
    // },
  },

  methods: {
    existsInRoutePlanner(obj) {
      return this.$store.getters["poi/existsInRoutePlanner"](obj);
    },
    toggleToRoutePlanner(obj) {
      if (this.existsInRoutePlanner(obj)) {
        this.$store.commit("poi/removeRoute", obj);
      } else {
        this.$store.dispatch("poi/addRoute", obj);
      }
    },
    zuwegung(obj) {
      return obj.properties.Zuwegung;
    },

    coordinates(obj) {
      return {
        lat: obj.geometry.coordinates[1],
        lng: obj.geometry.coordinates[0],
      };
    },

    navigateTo(obj) {
      window.location = this.isMobile
        ? `google.navigation:q=${obj.geometry.coordinates[1]},${obj.geometry.coordinates[0]}`
        : `https://maps.google.com/maps?daddr=${obj.geometry.coordinates[1]},${obj.geometry.coordinates[0]}&amp;ll=`;
    },

    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },

    switchView() {
      console.log("isListView value", this.$store.state.settings.isListView);
      this.$store.commit("switchView");
    },

    querySelection(search) {
      const start = performance.now();
      this.isSearching = true;
      this.items = this.allItems
        .filter((obj) => {
          return (
            obj.label
              ?.toLocaleLowerCase()
              ?.indexOf(search.toLocaleLowerCase()) > -1 ||
            obj.properties.Stationskennzeichen?.indexOf(
              search.toLocaleLowerCase()
            ) > -1 ||
            obj.properties.kv_kennzeichen
              ?.toLocaleLowerCase()
              ?.indexOf(search.toLocaleLowerCase()) > -1 ||
            obj.properties.Schlüsselbezeichnung?.toLocaleLowerCase()?.indexOf(
              search.toLocaleLowerCase()
            ) > -1
          );
        })
        .slice(0, 15);
      const end = performance.now();
      this.took = end - start;
      console.log("Filtering vuexstore took: ", end - start + "ms");
      this.isSearching = false;
    },
  },

  watch: {
    // isOnline: {
    //   handler: function (status) {
    //     console.log("online status", status);
    //     if (status) {
    //       this.$router.push("map");
    //     } else {
    //       this.$store.dispatch("checkStorage");
    //     }
    //   },
    //   immediate: true,
    // },

    onLine: {
      handler: function (status) {
        if (status) {
          this.isDisabled = false;
          if (!this.$store.state.settings.isListView) {
            if (this.isMobile) {
              this.$router.push("/map");
            } else {
              this.$router.push("/desktop");
            }
          }
        } else {
          console.log("is offline");
          this.isDisabled = true;
        }
      },
      immediate: true,
    },

    "$store.state.settings.isListView": {
      handler: function (status) {
        console.log("isListView", status);
        if (!status) {
          if (this.isMobile) {
            this.$router.push("/map");
          } else {
            this.$router.push("/desktop");
          }
        }
      },
    },

    "$store.state.rights.isLoading": {
      handler: function (isLoading) {
        if (!isLoading) {
          this.$store.dispatch("poi/getItems");
        }
      },
      immediate: true,
    },

    search: debounce(function (val) {
      if (val.length > 2) {
        val && this.querySelection(val);
      }
    }, 500),
  },

  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    //this.$store.dispatch("poi/getItems");
  },

  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
